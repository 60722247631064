import { useEffect, useState, useRef } from 'react'

// Deps
import Cleave from 'cleave.js/react';

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';

const inputs = [0, 1, 2, 3];

const PinInput = ({ className, onComplete, onChange, error, pin, disabled, pinRef, onResend }) => {

	const timerInstance = useRef(false);
	const timeLeft = useRef(0);
	const pinElems = useRef([]);

	const [vals, setVals] = useState(inputs.map(() => ''));
	const [formattedTimeLeft, setFormattedTimeLeft] = useState('00:00');
	const debouncedVals = useDebounce(vals);

	// Timer Controls
	const setPrettyTime = (seconds) => {
		const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
		const secs = (seconds - (mins * 60)).toString().padStart(2, '0');

		setFormattedTimeLeft(`${mins}:${secs}`)
	}

	const stopTimer = () => {
		if (timerInstance.current) {
			clearInterval(timerInstance.current);
			timerInstance.current = false;
		}
	}

	const startTimer = () => {
		if (timerInstance.current) {
			clearInterval(timerInstance.current);
		}

		timeLeft.current = 120;
		setPrettyTime(timeLeft.current)

		timerInstance.current = setInterval(() => {
			const newTime = timeLeft.current - 1;

			if (newTime < 0) {
				stopTimer();
			}
			else {
				timeLeft.current = newTime;
				setPrettyTime(newTime)
			}

		}, 1000)
	}

	useEffect(() => {
		const newVal = vals.join('');
		onChange(newVal);
	}, [vals, onChange]);

	useEffect(() => {
		const newVal = debouncedVals.join('');
		if(newVal.length === inputs.length) {
			onComplete(newVal);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedVals]);

	useEffect(() => {
		if (pin === '') {
			setVals(inputs.map(() => ''));
		}
	}, [pin])

	useEffect(() => {
		setVals(inputs.map(() => ''));
		startTimer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pinRef])

	useEffect(() => {
		startTimer();
		// if (pinElems.current && pinElems.current.length ) {
		// 	pinElems.current[0].focus();
		// }

		return () => {
			stopTimer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Input Controls
	const setVal = (nth, val) => {
		let newVals = [...vals];
		newVals[nth] = val;
		setVals(newVals);
	}

	const inputChange = (index, e) => {
		// Paste & 4 characters
		if (index === 0 && e.target.value.length === inputs.length) {
			let newVal = e.target.value;

			setVal(index, newVal[index]);

			setTimeout(() => {
				const newVals = newVal.split('');
				setVals(newVals);

				pinElems.current[inputs.length - 1].focus();
				// setTimeout(() => {
				// 	onComplete(newVal);
				// }, 10)
			}, 20)
		}
		// Not paste
		else if (e.target.value.length >= 1) {
			setVal(index, e.target.value);
			let newVal = e.target.value[e.target.value.length - 1];
			pinElems.current[index].blur();

			if (index < (inputs.length - 1)) {
				pinElems.current[index + 1].focus();
				pinElems.current[index + 1].select();
			}
			setTimeout(() => {
				pinElems.current[index].value = newVal;
				setVal(index, newVal);
				if (index >= (inputs.length - 1)) {
					// setTimeout(() => {
					// 	onComplete(vals.join());
					// }, 150)
				}
				else if(pinElems.current[index + 1]) {
					pinElems.current[index + 1].focus();
					pinElems.current[index + 1].select();
				}
			}, 20)
		}
		// Removal
		else if (e.target.value.length === 0) {
			setVal(index, '');
		}

	}

	const inputKeyPress = (index, e) => {
		if (index !== 0 && e.keyCode === 8 && e.target.value === '') {
			pinElems.current[index - 1].focus();
			pinElems.current[index - 1].select();
		}
		else if (index !== 0 && e.keyCode === 37) {
			pinElems.current[index - 1].focus();
			pinElems.current[index - 1].select();
		}
		else if (index < (inputs.length - 1) && e.keyCode === 39) {
			pinElems.current[index + 1].focus();
			pinElems.current[index + 1].select();
		}
	}

	return (
		<div className={'pininput ' + className + (error ? ' error' : '')}>
			<div className="pininput-charwrap">
				{inputs.map((nth) => (
					<Cleave
						key={nth}
						className="pininput-char"
						ref={(e) => {
							if (e !== null) { pinElems.current[nth] = e.element }
						}}
						autoFocus={nth === 0 ? true : undefined}
						disabled={disabled}
						pattern="[0-9]"
						inputMode="numeric"
						value={vals[nth]}
						onInput={(e) => { inputChange(nth, e); }}
						onKeyUp={(e) => { inputKeyPress(nth, e); }}
						onFocus={() => { if (pinElems.current.length) { pinElems.current[nth].select() } }}
						options={{
							numericOnly: true,
							blocks: [nth === 0 ? 4 : 1],
						}} />
				))}
			</div>

			<div className="pininput-controls">
				{onResend &&
					<button
						type="button"
						disabled={disabled}
						className="controls-resend"
						onClick={() => { onResend() }}>
						tekrar gönder
					</button>
				}

				<span className="controls-timer">{formattedTimeLeft}</span>
			</div>
		</div>
	)
}

export default PinInput